<template>
  <div class="kecheng_add" v-loading="loading">
    <el-form ref="form" label-width="80px">
      <el-form-item label="班级名称">
        <el-select clearable v-model="className" placeholder="请选择班级">
          <el-option
            v-for="item in classes"
            :label="item"
            :value="item"
            :key="item"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="学科">
        <el-select v-model="discipline" placeholder="请选择对应学科">
          <el-option label="JAVA" value="Java分布式开发V12"></el-option>
          <el-option label="HTML5" value="HTML5大前端就业班V12"></el-option>
          <el-option label="全端" value="Java全端开发"></el-option>
          <el-option label="机器视觉" value="机器视觉"></el-option>
          <el-option label="嵌入式" value="嵌入式"></el-option>
          <el-option label="Java全栈+AIGC开发" value="Java全栈+AIGC开发"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程阶段" prop="c_phase">
        <!-- <el-input v-model.number="ruleForm.c_phase"></el-input> -->
        <el-select
          v-model="c_phase"
          placeholder="请选择对应学科"
          @change="changeAction"
        >
          <el-option
            :label="item.jieduanming"
            v-for="(item, index) in phases"
            :key="index"
            :value="item.jieduanming"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="课程名称" prop="c_name">
        <el-select v-model="c_name" placeholder="请选择对应课程">
          <el-option
            :label="item.kechengming"
            v-for="(item, index) in names"
            :key="index"
            :value="item.kechengming"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="作业名称" prop="zuoye">
        <div class="input">
          <el-input
            placeholder="请输入今晚作业"
            v-model="zuoye"
            clearable
            maxlength="20"
          >
          </el-input>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">立即创建</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  name: "XiangmuAdd",
  data() {
    return {
      value: [],
      options: [],
      className: "",
      classes: [],
      jieduan: [],
      xms: [],
      c_name: "",
      c_phase: "",
      zuoye: "",
      discipline: "HTML5大前端就业班V12",
      phases: [],
      names: [],
      kechengs: [],
      courses: [],
      loading: false,
    };
  },
  watch: {
    discipline: {
      async handler(newV) {
        this.discipline = newV;
        this.filterData();
      },
      immediate: false,
    },
  },
  async created() {
    let res2 = await this.$datas.getCourses;
    this.courses = res2.data;
    this.filterData();

    let res = await this.$datas.getXM;
    let res1 = await this.$datas.home;
    //获取阶段
    let data1 = [];
    for (let i in res.data) {
      let item = res.data[i];
      let exist = -1;
      for (let j in data1) {
        if (data1[j].jieduan_name == item.jieduan_name) {
          exist = j;
          break;
        }
      }
      if (exist != -1) {
        //存在，此时直接存储数据
        data1[exist].children.push({
          label: item.xiangmu_name,
          value: item.xiangmu_id,
        });
      } else {
        //不存在，此时创建新数据存储
        data1.push({
          value: item.jieduan_id,
          label: item.jieduan_name,
          children: [
            {
              label: item.xiangmu_name,
              value: item.xiangmu_id,
            },
          ],
        });
      }
    }
    this.options = data1;
    // 处理班级
    let data2 = res1.data.msg.data;
    for (let key in data2) {
      this.classes.push(data2[key].className);
    }
  },
  methods: {
    filterData() {
      let jieduan = [];
      for (let i in this.courses) {
        let item = this.courses[i];
        if (item.kechengtixi == this.discipline) {
          var orExist = false;
          for (let j in jieduan) {
            if (jieduan[j].jieduanming == item.jieduanming) {
              orExist = true;
              break;
            }
          }
          if (!orExist) {
            jieduan.push({
              jdbianhao: item.jdbianhao,
              jieduanming: item.jieduanming,
            });
          }
        }
      }
      this.phases = jieduan;
    },
    async onSubmit() {
      this.loading = true;
      let meta = {
        xueke: this.discipline,
        jieduan_name: this.c_phase,
        kecheng_name: this.c_name,
        className: this.className,
        zuoye_name: this.zuoye,
      };
      for (let i in this.phases) {
        let item = this.phases[i];
        if (item.jieduanming == this.c_phase) {
          meta = { ...meta, jieduan_id: item.jdbianhao };
          break;
        }
      }

      for (let i in this.names) {
        let item = this.names[i];
        if (item.kechengming == this.c_name) {
          meta = { ...meta, kecheng_id: item.kcbianhao };
          break;
        }
      }
      this.$datas.meta = meta;
      let res = await this.$datas.addZuoYeCheck;
      console.log(res);
      if (res.status == 200) {
        this.$message("检查记录已添加");
        this.$router.replace({name:"KeCheng"});
      } else {
        this.$message("检查记录添加失败");
      }
      this.loading = false;
    },
    handleChange(value) {
      this.value = value;
    },
    changeAction(kind) {
      let names = [];
      for (let i in this.courses) {
        let item = this.courses[i];
        if (item.jieduanming == kind && this.discipline == item.kechengtixi) {
          names.push({
            kcbianhao: item.kcbianhao,
            kechengming: item.kechengming,
          });
        }
      }
      this.names = names;
    },
  },
};
</script>
<style lang="less" scoped>
.kecheng_add {
  .input {
    width: 400px;
  }
}
</style>
