import { render, staticRenderFns } from "./addKC.vue?vue&type=template&id=55445c4c&scoped=true&"
import script from "./addKC.vue?vue&type=script&lang=js&"
export * from "./addKC.vue?vue&type=script&lang=js&"
import style0 from "./addKC.vue?vue&type=style&index=0&id=55445c4c&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "55445c4c",
  null
  
)

export default component.exports